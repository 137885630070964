<template>
    <Defaultlayout :userprofile="userprofile">
        <div>
            <section class="chat-section" v-if="isLoading==false">
                <div class="container" v-if="messages.length > 0 && chat_module == 1 && activeFlag == false">
                    <div class="row">
                        <div class="col-md-4 col-xl-3" id="people-list">
                            <div class="search">
                                <form>
                                    <div class="form-group">
                                        <label for="exampleInputEmail1" class="sr-only">Search</label>
                                        <input type="text" class="form-control" v-model="searchQuery" placeholder="Search" />
                                    </div>  
                                </form>
                            </div>
                            <div class="people-list">
                                <ul class="list list-unstyled" v-for="(group,index) in filterResult" :key="index">
                                    <li class="media" :class="{'active': previousIndex == index}" @click="openChatHistory(group, index);">
                                        <div>
                                            <div class="profile-pic">
                                                <img :src="group.groupIcon" alt="avatar" />
                                                <h4 class="unread-count" v-if="group.unreadcus > 0">
                                                    {{group.unreadcus}}
                                                </h4>
                                            </div>
                                        </div>
                                        <div class="about media-body" >
                                            <h4 class="order-number">
                                                {{group.groupOrderDetails.order_no}}
                                            </h4>
                                            <h5 class="item-name" v-if="group.groupOrderDetails.store!=undefined">{{group.groupOrderDetails.store.store_name}}</h5>
                                            <h5 class="location">{{group.groupOrderDetails.store.area_name}}</h5>
                                        </div> 
                                        <!-- creating issue squaring of image -->
                                        <div v-for="(user,index) in userGroupArray.filter(x => x.indexOf(group.groupId) > -1)" :key="index">                               
                                            <div v-if="user.includes('_A')">
                                                <img src="../../assets/images/svgs/bike.svg">
                                            </div>
                                            <div v-else>
                                                <img class="store-image" src="../../assets/images/svgs/store.svg">
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div :class="show_chat == false ? 'col-md-8 col-xl-9 chat-col' : 'col-md-8 col-xl-9 chat-col show'">
                            <div class="chat">
                                <div class="chat-header">
                                    <div class="media align-items-center">
                                        <div class="back" id="backBtn" @click="closeChatHistory()"></div>
                                        <div class="profile-pic">
                                            <a><img :src="clickOrderDetail.groupIcon" alt="avatar" /></a>
                                        </div>
                                        <div class="chat-about media-body p-0">
                                            <div class="chat-with" v-if="clickOrderDetail.groupOrderDetails.store!=undefined"> 
                                                <a href="javascript:void" @click="redirectToStore(clickOrderDetail.groupOrderDetails.store.store_id, clickOrderDetail.groupOrderDetails.store.store_slug)">
                                                    {{ `${clickOrderDetail.groupOrderDetails.store.store_name} (${clickOrderDetail.groupOrderDetails.order_no})` }}
                                                </a>
                                            </div>
                                            <ul class="icon-listing list-unstyled">
                                                <li>
                                                    <a href="javascript:void(0);" data-toggle="modal" data-target="#SelectFromorder" @click="chatItems(clickOrderDetail.groupOrderDetails.order_id)"><img src="../../assets/images/chat-icons/basket.svg" alt="basket"></a>
                                                </li>
                                                <li>
                                                    <a href="javascript:void(0);" data-toggle="modal" @click="getOrderDetails(clickOrderDetail.groupOrderDetails.order_id)"><img src="../../assets/images/chat-icons/report.svg" alt="report"></a>
                                                    <OrderDetail :orderid="orderdetail.order_id"/>
                                                </li>
                                            </ul>
                                        </div> 
                                    </div>
                                </div>
                                <div class="chat-history">
                                    <ul class="list-unstyled w-100 mb-4 chat-listing" v-for="(msg,index) in messages" :key="index" >
                                        <li class=" sender message-box media" v-if="msg.sender != profile_email">
                                            <div class="profile-pic">            
                                                <img :src="msg.logo" alt="avatar">
                                            </div>
                                            <div class="media-body">
                                                <div class="message my-message">
                                                    <span v-if="msg.type == 0">
                                                        <span style="word-break:break-all;white-space: pre-line;" v-if="msg.message.length >= 220" v-html="limitTo(msg.message)"></span>
                                                        <a href="javascript:void(0);" @click="activateReadMore(index)" v-show="msg.message.length >= 220  && msg.isVisible == false"> show more</a>
                                                        <span v-if="(msg.message.length < 220  || msg.isVisible == true)" style="word-break:break-all;white-space: pre-line;" v-html="msg.message"></span>
                                                        <a href="javascript:void(0);" @click="activateReadMore(index)" v-show="msg.isVisible == true"> show less</a>
                                                    </span>
                                                    <span v-if="msg.message != '' && msg.type === 1">
                                                        <a :href="msg.message" target="_blank" class="msg-img">
                                                            <img :src="msg.message" class="img-fluid"/>
                                                        </a>
                                                    </span>
                                                    <span v-if="msg.type == 2">
                                                        <h5>Items from Store</h5>
                                                        <div class="order-box card-body">
                                                            <div class="profile-id ">
                                                                <div class="media align-items-sm-center" v-for="(store_items,index) in JSON.parse(msg.message)" :key="'storeitem'+index">
                                                                    <div class="ordered-profile mr-3">
                                                                        <img :src="store_items.item_image" :alt="store_items.item_name">
                                                                    </div>
                                                                    <div class="media-body">
                                                                        <div class="row form-row ">
                                                                            <div class="col-12  ">
                                                                                <div class="row form-row align-items-center" v-for="(variant,index) in store_items.item_variants" :key="'variant'+index">
                                                                                    <div class="col-sm-9 order-description" v-if="variant.is_added == 1">
                                                                                        <h5>{{store_items.item_name}}</h5>
                                                                                        <span v-if="variant.packaging_name != null">
                                                                                        {{ variant.packaging_name }}
                                                                                        </span>
                                                                                        <span v-if="parseFloat(variant.unit_quantity) != '0'">
                                                                                        {{ Number( variant.unit_quantity ).toFixed(1) }}
                                                                                        </span>
                                                                                        <span v-if="variant.unit_name != null">
                                                                                        {{ variant.unit_name }}
                                                                                        </span>
                                                                                    </div>
                                                                                    <div class="col-sm-3 order-rate text-right" v-if="variant.is_added == 1">
                                                                                        <h5 class="per-price">{{variant.quantity}} &#215; <span class="rupee">{{currency_symbol}}</span>{{variant.discounted_price == undefined ? variant.price : variant.discounted_price }}</h5>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </span>
                                                    <div v-else-if="msg.message != '' && msg.type === 5" style="text-align: center;">
                                                        <a :href="msg.message"><img src="../../assets/images/chat-icons/ic_pdf.svg" type="file" style="width: 50px; height: 50px; align-items: center;"></a><br>{{msg.message | strippedFileURL }}
                                                    </div>
                                                    <span v-if="msg.type == 3">
                                                        <h5>Items from Order</h5>
                                                        <div class="order-box card-body">
                                                            <div class="profile-id ">
                                                                <div class="media align-items-sm-center" v-for="(store_items,index) in JSON.parse(msg.message)" :key="'storeitem'+index">
                                                                    <div class="ordered-profile mr-3">
                                                                        <img :src="store_items.item_image" :alt="store_items.item_name">
                                                                    </div>
                                                                    <div class="media-body">
                                                                        <div class="row form-row ">
                                                                            <div class="col-12  ">
                                                                                <div class="row form-row align-items-center">
                                                                                    <div class="col-sm-9 order-description">
                                                                                        <h5>{{store_items.item_name}}</h5>
                                                                                        <span v-if="store_items.packaging_name != null">
                                                                                        {{ store_items.packaging_name }}
                                                                                        </span>
                                                                                        <span v-if="parseFloat(store_items.unit_quantity) != '0'">
                                                                                        {{ Number( store_items.unit_quantity ).toFixed(1) }}
                                                                                        </span>
                                                                                        <span v-if="store_items.unit_name != null">
                                                                                        {{ store_items.unit_name }}
                                                                                        </span>
                                                                                    </div>
                                                                                    <div class="col-sm-3 order-rate text-right">
                                                                                        <h5 class="per-price">{{store_items.quantity.split(".")[0]}} &#215; <span class="rupee">{{currency_symbol}}</span>{{store_items.price}}</h5>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </span>
                                                    <div class="message-data">
                                                        <span class="message-data-time">{{ msg.messageTime }}</span>
                                                    </div>
                                                </div>
                                                
                                            </div>
                                        </li>
                                        <li class="rechiever message-box media" v-if="msg.sender == profile_email">
                                            <div class="profile-pic">
                                                <img v-if="msg.logo != ''" :src="msg.logo" alt="avatar">
                                                <img v-else src="https://i.ibb.co/jy59YWL/man.png" alt="avatar">
                                                <!-- <avatar v-else-if="userprofile.name != ''" :fullname="(userprofile.name.split(' ')[0] + ' ' +userprofile.name.split(' ')[1])" :size="30" :rounded="false" :color="avatarBackgroundColor"></avatar> -->
                                            </div>
                                            <div class="media-body">
                                                <div :class="msg.readstatus === 'yes' ? 'message other-message message-seen' : 'message other-message'">
                                                    <span v-if="msg.message != '' && msg.type === 1">
                                                        <a href="javascript:void(0)" target="_blank" class="msg-img" @click="openImageMsg(msg.message)">
                                                            <img :src="msg.message" class="img-fluid"/>
                                                        </a>
                                                    </span>
                                                    
                                                    <div v-else-if="msg.message != '' && msg.type === 5" style="text-align: center;">
                                                        <a :href="msg.message"><img src="../../assets/images/chat-icons/ic_pdf.svg" type="file" style="width: 50px; height: 50px; align-items: center;"></a><br>{{msg.message | strippedFileURL }}
                                                    </div>

                                                    <span v-else-if="msg.message != '' && msg.type === 3">
                                                        <div class="media-body">
                                                            <div class="message my-message">
                                                                <h5>{{ msg.itemfrom }}</h5>
                                                                <div class="order-box card-body">
                                                                    <div class="profile-id">
                                                                        <div class="media align-items-sm-center" v-for="(itemvariants,index) in JSON.parse(msg.message)" :key="index">
                                                                                <div class="ordered-profile mr-3">
                                                                                    <img :src="itemvariants.item_image" alt="apple">
                                                                                </div>
                                                                                <div class="media-body">
                                                                                    <div class="row form-row">
                                                                                        <div class="col-12">
                                                                                            <div class="row form-row align-items-center">
                                                                                                <div class="col-sm-9 order-description">
                                                                                                    <h6>{{ itemvariants.item_name }}</h6>
                                                                                                    <span v-if="itemvariants.packaging_name != null">
                                                                                                    {{ itemvariants.packaging_name }}
                                                                                                    </span>
                                                                                                    <span v-if="parseFloat(itemvariants.unit_quantity) != '0'">
                                                                                                    {{ Number( itemvariants.unit_quantity ).toFixed(1) }}
                                                                                                    </span>
                                                                                                    <span v-if="itemvariants.unit_name != null">
                                                                                                    {{ itemvariants.unit_name }}
                                                                                                    </span>
                                                                                                </div>
                                                                                                <div class="col-sm-3 order-rate text-right">
                                                                                                    <h5 class="per-price">{{itemvariants.quantity.split(".")[0]}} &#215; <span class="rupee">{{currency_symbol}}</span>{{itemvariants.discounted_price == undefined ? itemvariants.price : itemvariants.discounted_price }}</h5>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>                                                
                                                    </span>
                                                    <span v-else-if="msg.message != '' && msg.type == 0">
                                                        <span style="word-break:break-all;white-space: pre-line;" v-if="msg.message.length >= 220" v-html="limitTo(msg.message)"></span>
                                                        <a href="javascript:void(0);" @click="activateReadMore(index)" v-show="msg.message.length >= 250  && msg.isVisible == false"> show more</a>
                                                        <span style="word-break:break-all;white-space: pre-line;" v-if="(msg.message.length < 220  || msg.isVisible == true)" v-html="msg.message"></span>
                                                        <a href="javascript:void(0);" @click="activateReadMore(index)" v-show="msg.isVisible == true"> show less</a>
                                                    </span>
                                                    <div class="message-data"> 
                                                        <span class="message-data-time">{{ msg.messageTime }}</span>
                                                        <span class="marker">
                                                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 15" width="18" height="17"><path fill="currentColor" d="M15.01 3.316l-.478-.372a.365.365 0 0 0-.51.063L8.666 9.879a.32.32 0 0 1-.484.033l-.358-.325a.319.319 0 0 0-.484.032l-.378.483a.418.418 0 0 0 .036.541l1.32 1.266c.143.14.361.125.484-.033l6.272-8.048a.366.366 0 0 0-.064-.512zm-4.1 0l-.478-.372a.365.365 0 0 0-.51.063L4.566 9.879a.32.32 0 0 1-.484.033L1.891 7.769a.366.366 0 0 0-.515.006l-.423.433a.364.364 0 0 0 .006.514l3.258 3.185c.143.14.361.125.484-.033l6.272-8.048a.365.365 0 0 0-.063-.51z"></path></svg>
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </li>
                                    </ul>
                                </div> <!-- end chat-history -->
                                

                                <div class="msg-bottom">
                                    <form class="form-inline ">
                                        <div class=" form-group chat-message">
                                            <textarea name="message-to-send" id="message-to-send" placeholder ="Type a message" value="" rows="1" v-model="userInputMessage" @keyup.enter="sendMessage()"></textarea>
                                        </div> <!-- end chat-message -->

                                        <div class=" form-group">
                                            <span class="media-btn" type="file">
                                                <img src="../../assets/images/chat-icons/gallary.svg" alt="gallary" @click="onPickFile">
                                                <input type="file" style="display:none;" ref="fileInput" class="fileupload" id="fileupload" v-validate="'ext:jpeg,jpg,bmp,png,gif,tiff'" data-vv-as="file" :class="{error: errors.has('file')}"  @change="onFileChange">
                                            </span>
                                        </div>

                                        <button type="button" :style="colorObj" class="btn btn-primary" @click="sendMessage()">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                                                <g id="send-icon" transform="translate(-1216 -678)">
                                                    <path id="Path_64" data-name="Path 64" d="M1231.425,702h-2.487a1.689,1.689,0,0,1-1.686-1.615l-1.4-8.235-8.235-1.4a1.689,1.689,0,0,1-1.615-1.686v-2.487a4.023,4.023,0,0,1,3.073-3.916l18.847-4.612.008,0a1.687,1.687,0,0,1,2.027,2.027l0,.008-4.612,18.847a4.023,4.023,0,0,1-3.916,3.073Zm6.847-22.495-18.842,4.611a2.525,2.525,0,0,0-1.93,2.459v2.487a.188.188,0,0,0,.187.188.752.752,0,0,1,.126.011l8.813,1.5a.748.748,0,0,1,.613.613l1.5,8.813a.749.749,0,0,1,.011.125.188.188,0,0,0,.188.188h2.487a2.525,2.525,0,0,0,2.459-1.93l4.611-18.842a.188.188,0,0,0-.223-.223Z" fill="#fff"/>
                                                    <path id="Path_65" data-name="Path 65" d="M1226.5,692.25a.75.75,0,0,1-.53-1.28l7.5-7.5a.75.75,0,0,1,1.06,1.06l-7.5,7.5a.744.744,0,0,1-.53.22Z" fill="#fff"/>
                                                </g>
                                            </svg>
                                        </button>
                                    </form>
                                </div> 
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row" v-if="activeFlag == true">
                    <div class="basket-order col-lg-12 mt-4 mt-lg-0">
                      <div class="table-responsive basket-list">
                        <div class="empty-box text-center">
                          <div class="contents">
                            <div class="empty-image">
                              <img src="../../assets/images/profile-icons/chat.svg" alt="empty-box">
                            </div>
                          <h3>You don't have any active chat.</h3>
                          </div>
                          <a href="/" :style="colorObj" class="btn button-style btn-large hoverEffect">Go to Home</a>
                        </div>
                      </div>
                    </div>
                </div>
            </section>
            <div class="container mt-5" v-if="isLoading==true">
                <p class="text-center">
                    <img src="../../assets/images/loading.gif" />
                </p>
            </div>
            <div id="SelectFromorder" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="item-submit-details" area-hidden="true" ref="orderitemmodal">
                <div class="modal-dialog" role="document">
                    <div class="modal-content">
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">×</span>
                        </button>
                        <div class="modal-body">
                            <h2 class="section-heading mb-5">Order Details</h2>
                            <div class="order-box card-body">
                                <div v-for="(items,index) in itemdetails.items" :key="'items'+index">
                                    <div class="profile-id"  v-for="(variants,ind) in items.item_variants" :key="ind">
                                        <div class="media align-items-sm-center" v-if="variants.is_added == 1">
                                            <div class="ordered-profile mr-3">
                                                <img :src="items.item_image">
                                            </div>
                                            <div class="media-body">
                                                <div class="row form-row">
                                                    <div class="col-12">
                                                        <div class="row form-row align-items-center">
                                                            <div class="col-sm-9 order-description">
                                                                <h5>{{ items.item_name }}</h5>
                                                                <span v-if="variants.packaging_name != null">
                                                                {{ variants.packaging_name }}
                                                                </span>
                                                                <span v-if="parseFloat(variants.unit_quantity) != '0'">
                                                                {{ Number( variants.unit_quantity ).toFixed(1) }}
                                                                </span>
                                                                <span v-if="variants.unit_name != null">
                                                                {{ variants.unit_name }}
                                                                </span>
                                                            </div>
                                                            <div class="col-sm-3 order-rate text-right">
                                                                <h5 class="per-price">{{variants.quantity.split(".")[0]}} &#215; <span class="rupee">{{currency_symbol}}</span>{{ variants.item_price }}</h5>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <form class="available-option">
                                                <div class="form-fields">
                                                    <div class="form-row ">
                                                        <div class="d-flex flex-wrap radio-dt">
                                                            <input
                                                            type="checkbox"
                                                            v-model="submitItems"
                                                            :value="variants.item_variants_id"
                                                            :id="variants.item_variants_id"
                                                            :name="variants.item_variants_id"
                                                            >
                                                            <label :for="variants.item_variants_id" class="mr-0">&nbsp;</label>
                                                        </div>
                                                    </div>
                                                </div>  
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="button row mx-0 justify-content-center mt-3">
                                <span class="btn btn-secondary" id="CreditFail" data-dismiss="modal" aria-label="Close">Cancel</span>
                                <button :style="colorObj" class="btn button-style hoverEffect w-auto ml-2" type="submit" @click="addOrderItems()">Submit</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </Defaultlayout>
</template>

<script>
import Defaultlayout from "../../layouts/Defaultlayout";
import firebase from 'firebase/app';
import 'firebase/storage';
import moment from 'moment';
import { bus } from "../../main";
//import Avatar from "vue-avatar-component";
import OrderDetail from '../order/OrderDetail';
import helper from "../../store/helper";
import localconfig from "../../global_settings.json"

export default {
    name: "Chat",
    props: {
        chatorderid: {
            type: Number
        }
    },
    mixins: [helper],
    components: {
        Defaultlayout,
        OrderDetail,
        //Avatar
    },
    data(){
        return {
          profile: {
            user_id:''
          },
          groupDetailsArray:[],
          messages:[],
          searchQuery: null,
          orderdetail: {
            order_id: null
          },
          userInputMessage: null,
          profile_email: '',
          userprofile: null,
          isLoading: false,
          previousIndex: 0,
          getchatimage: null,
          itemdetails: '',
          submitItems: [],
          userGroupArray: [],
          currency_symbol: "₹",
          chat_module: 1,
          avatarBackgroundColor:"",
          show_chat: false,
          uid: "",
          clickOrderDetail: "",
          display: 0,
          chatImage: "",
          chatDoc: "",
          activeFlag: false,
          groupArray: [],
          mainGroupArray: [],
        };
    },
    mounted(){
        this.isLoading = true;
        bus.$on("globalsetting",data => {
            if(Object.keys(data).length != 0){
                this.chat_module = JSON.parse(data).chat_module;
            } else {
                this.chat_module = localconfig.chat_module;
            }

            if(this.chat_module == 0){
                this.$toast.error("No chat available" , { timeout: 1500 });
                var vm = this;
                setTimeout(function(){
                    vm.$router.go(-1);
                }, 1500);
            }
        });

        if(localStorage.getItem("user") != null){
            this.userprofile = JSON.parse(localStorage.getItem("user"))
            var user_id = (localStorage.getItem("user") != null) ? JSON.parse(localStorage.getItem("user")).user_id : "0";
            this.profile_email = user_id + '.customer@gbazaar.com';
            this.authentication();
        } else {
            this.$router.go(-1);
        }
        
        this.currency_symbol = localStorage.getItem("currency_symbol") != null && localStorage.getItem("currency_symbol") != "null"?localStorage.getItem("currency_symbol"):"₹";

        window.$(this.$refs.orderitemmodal).on("show.bs.modal", this.openOrderItemModal);
    },
    updated(){
        this.avatarBackgroundColor = this.buttonColor.backgroundColor;
        window.$(document).ready(function(){
            window.$('<style>.chat-section .people-list .media.active {background-color : '+localStorage.getItem("button_hover_color")+';}</style>').appendTo('body')            
            window.$('.chat-screen .msg-bottom svg g#send-icon path').css({ fill: localStorage.getItem("css_button_customer_default_font")})
        })
        window.$('<style>.available-option .radio-dt input[type="checkbox"] ~ label:before {border-top-color: '+localStorage.getItem("button_default_color")+'}</style>').appendTo('body')
        window.$('<style>.available-option .radio-dt input[type="checkbox"] ~ label:before {border-bottom-color: '+localStorage.getItem("button_default_color")+'}</style>').appendTo('body')
        window.$('<style>.available-option .radio-dt input[type="checkbox"] ~ label:before {border-left-color: '+localStorage.getItem("button_default_color")+'}</style>').appendTo('body')
        window.$('<style>.available-option .radio-dt input[type="checkbox"] ~ label:before {border-right-color: '+localStorage.getItem("button_default_color")+'}</style>').appendTo('body')
        window.$('<style>.available-option .radio-dt input[type="checkbox"] ~ label:after {background-color: '+localStorage.getItem("button_default_color")+'}</style>').appendTo('body')
    },
    computed:{
        filterResult(){
            let result = this.groupDetailsArray;
            if(this.searchQuery){
                result = result.filter(group => {
                    if(group.groupOrderDetails!=undefined){
                        return (group.groupOrderDetails.order_no.toLowerCase().includes(this.searchQuery.toLowerCase()) || group.groupOrderDetails.store.store_name.toLowerCase().includes(this.searchQuery.toLowerCase()) || group.groupOrderDetails.store.area_name.toLowerCase().includes(this.searchQuery.toLowerCase()))
                    }
                });
            } else {
                return this.groupDetailsArray.slice().sort((a,b) =>{
                    var aordertime = this.normalizeMixedDataValue(a.groupOrderDetails.order_date)
                    var bordertime = this.normalizeMixedDataValue(b.groupOrderDetails.order_date)
                    return (aordertime > bordertime ? -1 : 1)
                });
            }
            result = result.sort((a,b) => {
                let aorder_no = a.groupOrderDetails.order_no.toLowerCase() ;
                let border_no = b.groupOrderDetails.order_no.toLowerCase();
                        
                if (aorder_no < border_no) {
                return 1;
                }
                if (aorder_no > border_no) {
                return -1;
                }
                return 0;
            })
            return result;
        },
    },
    filters: {
        strippedFileURL: function(value) {
            if (value.length > 15) {
                return value.slice(74, 102) + "...";
            }
            return value;
        },
    },
    methods:{
        limitTo: function(value) {
            if (!value) return '';
            return value.substring(0, 220);
        },
        openChatHistory(details, index){
            this.getChat(details);
            this.previousIndex = index;
            this.show_chat = true;
        },
        closeChatHistory(){
            this.show_chat = false;
        },
        activateReadMore(el){
            var vm = this
            if (!vm.messages[el].isVisible) {
                vm.messages[el].isVisible = true;
            } else  {
                vm.messages[el].isVisible = false;
            }
        },
        normalizeMixedDataValue( value ) {
            var padding = "000000000000000";
            value = value.replace(
                /(\d+)((\.\d+)+)?/g,
                function( $0, integer, decimal, $3 ) {
                    if ( decimal !== $3 ) {
                        return(
                            padding.slice( integer.length ) +
                            integer +
                            decimal
                        );
                    }
                    decimal = ( decimal || ".0" );
                    return(
                        padding.slice( integer.length ) +
                        integer +
                        decimal +
                        padding.slice( decimal.length )
                    );
                }
            );
            return( value );
        },
        scrollToBottom(){
            let box = document.querySelector('.chat-history');
            box.scrollTop = box.scrollHeight;
        },
        chatTimeStamp(date){
            return moment(parseInt(date)).format('DD MMMM YYYY HH:mm:ss');
        },
        onPickFile(){
            this.$refs.fileInput.click()
        },
        onFileChange(e) {
            var files = e.target.files || e.dataTransfer.files;
            if (!files.length) return;
            var file = files[0];
            var extension = file.type.split('/').pop().toLowerCase();
            if (extension == 'png' || extension == 'PNG' || extension == 'jpg' || extension == 'jpeg') {
                firebase.storage().ref().child(file.name).put(file).then(snapshot => {
                    snapshot.ref.getDownloadURL().then((url) => {
                        this.chatImage = url;
                        this.sendImage();
                    });
                });
            } else if (extension == 'pdf' || extension == 'doc') {
                firebase.storage().ref().child(file.name).put(file).then(snapshot => {
                    snapshot.ref.getDownloadURL().then((url) => {
                        this.chatDoc = url;
                        this.sendDocument();
                    });
                });
            } else {
                this.$toast.error("Please upload a valid file.");
            }
        },
        authentication(){
            var email = this.profile_email;
            var password = "login12*"
            if(email != null){    
                firebase.auth().signInWithEmailAndPassword(email, password).then(userCredential => {
                        var user = userCredential.user
                        this.uid = user.uid
                        this.getUserGroups(this.uid);
                }).catch((error) => {
                    this.isLoading = false;
                    this.activeFlag = true;
                    console.log(error);
                });
            }
        },
        getUserGroups(uid){
            var _this = this;
            // Fetch user groups collection
            firebase.firestore().collection("users").doc(String(uid)).get().then(
                queryUsers => {
                    var group_data = queryUsers.data();
                    if(group_data == undefined){
                        this.isLoading = false;
                        this.activeFlag = true;
                        return;
                    }

                    for (var i in group_data.groups){
                        _this.groupArray.push(group_data.groups[i]);
                    }

                    for (var j = 0; j <= _this.groupArray.length; j++) {
                        if (_this.groupArray[j] != null) {
                            var data = _this.groupArray[j].substr(0, _this.groupArray[j].indexOf('_'));
                            if (data != undefined) {
                                _this.mainGroupArray.push(data);
                            }
                            _this.userGroupArray.push(_this.groupArray[j]);
                        }
                    }
                    _this.getGroupDetails(_this.mainGroupArray);
                },
                error => {
                    this.activeFlag = true;
                    this.isLoading = false;
                    console.log(error);
                }
            );
        },
        getGroupDetails(mainGroupArray){
            if(mainGroupArray.length == 0){
                this.activeFlag = true;
                this.isLoading = false;
            }

            for (var k = 0; k <= mainGroupArray.length; k++) {
                if (mainGroupArray[k] != undefined) {
                    firebase.firestore().collection("groups").doc(mainGroupArray[k]).get().then(
                        queryGroups => {
                            this.display++;
                            var group_details = queryGroups.data();
                            if (group_details != undefined) {  
                                this.groupDetailsArray.forEach( function (event) {
                                    if(event.groupId == group_details.groupId){
                                        event.unread = group_details.unreadStoreMessages;
                                        event.unreadcus = group_details.unreadCustomerMessages;
                                    }
                                })
                                if (group_details.groupIcon != null || group_details.groupId != null || group_details.groupOrderDetails != null) {
                                    var orderDetails = JSON.parse(group_details.groupOrderDetails);
                                    for (var j = 0; j <= this.groupDetailsArray.length; j++) {
                                        if (this.groupDetailsArray[j] != undefined) {
                                            /*if (this.groupDetailsArray[j].orderId == orderDetails.order_id) {
                                                return;
                                            }*/
                                        }
                                    }
                                    var i = {
                                        groupIcon: group_details.groupIcon,
                                        groupId: group_details.groupId,
                                        groupOrderDetails: orderDetails,
                                        orderId: orderDetails.order_id,
                                        unread: group_details.unreadStoreMessages,
                                        unreadcus: group_details.unreadCustomerMessages
                                    }
                                    this.groupDetailsArray.push(i);

                                    this.groupDetailsArray = this.groupDetailsArray.slice().sort((a,b) =>{
                                        var aordertime = this.normalizeMixedDataValue(a.groupOrderDetails.order_date)
                                        var bordertime = this.normalizeMixedDataValue(b.groupOrderDetails.order_date)
                                        return (aordertime > bordertime ? -1 : 1)
                                    });
                                    if (mainGroupArray.length == this.display) {
                                        this.getChat(this.groupDetailsArray[0]);
                                        this.selectOrderChat();
                                        this.isLoading = false;
                                        return;
                                    }
                                }
                            }
                        },
                        error => {
                            this.activeFlag = true;
                            this.isLoading = false;
                            console.log(error);
                        }
                    )
                }
            }
        },
        getChat(item){
            this.clickOrderDetail = item;
            firebase.firestore().collection("groups").doc(this.clickOrderDetail.groupId).collection("messages").orderBy('time').onSnapshot(
                querySnapShot => {
                    if(this.clickOrderDetail.groupId == item.groupId){
                        this.messages = [];
                        this.clickOrderDetail.unreadcus = 0;
                        firebase.firestore().collection('groups').doc(this.clickOrderDetail.groupId).update({ unreadCustomerMessages: 0 });

                        querySnapShot.forEach(doc => {
                            var msgData = doc.data();
                            msgData.messageTime = this.chatTimeStamp(msgData.showtime)
                            msgData.isVisible = false;
                            this.messages.push(msgData);
                        });

                        setTimeout(() => { this.scrollToBottom(); this.updateStatus(item); }, 1);

                        this.isLoading = false;
                    } else {
                        this.isLoading = false;
                    }
                },
                error => {
                    this.isLoading = false;
                    console.log(error);
                }
            )
        },
        updateStatus(item){
            this.clickOrderDetail = item;
            firebase.firestore().collection('groups').doc(this.clickOrderDetail.groupId).collection('messages').orderBy('time')
            .onSnapshot(querySnapshot => {
                querySnapshot.forEach(doc => {
                    var data = doc.data();
                    if (data.sender != this.profile_email) {
                        firebase.firestore().collection('groups').doc(this.clickOrderDetail.groupId).collection('messages').doc(doc.id).update({ readstatus: "yes" });
                    }
                });
            });
        },
        sendMessage(){
            if(this.userInputMessage == null && this.userInputMessage.trim() == ''){
                return;
            }
            var input = new Object();
            input.message = this.userInputMessage;
            input.title = null;
            input.fileName = '';
            input.itemfrom = '';
            input.readstatus = 'no';
            input.type = 0;
            input.sender = this.profile_email;
            input.time = firebase.firestore.FieldValue.serverTimestamp();
            input.logo = JSON.parse(localStorage.getItem("user")).user_picture!=null && JSON.parse(localStorage.getItem("user")).user_picture!='' ? JSON.parse(localStorage.getItem("user")).user_picture : 'https://i.ibb.co/jy59YWL/man.png';
            input.showtime = Date.parse(new Date()).toString()

            if (input.message) {
                firebase.firestore().collection('groups').doc(this.clickOrderDetail.groupId).collection('messages').add(input).then(() => {
                this.scrollToBottom()
            });
                firebase.firestore().collection('groups').doc(this.clickOrderDetail.groupId).update({ unreadStoreMessages: Number(this.clickOrderDetail.unread) + 1 });
            } else {
                this.userInputMessage = null
            }
            this.userInputMessage = null;
        },
        sendImage(){
            var input = new Object();
            input.message = this.chatImage;
            input.title = null;
            input.fileName = '';
            input.itemfrom = '';
            input.readstatus = 'no';
            input.type = 1;
            input.sender = this.profile_email;
            input.time = firebase.firestore.FieldValue.serverTimestamp();
            input.logo = JSON.parse(localStorage.getItem("user")).user_picture!=null && JSON.parse(localStorage.getItem("user")).user_picture!='' ? JSON.parse(localStorage.getItem("user")).user_picture : 'https://i.ibb.co/jy59YWL/man.png';
            input.showtime = Date.parse(new Date()).toString();
            firebase.firestore().collection('groups').doc(this.clickOrderDetail.groupId).collection('messages').add(input).then(() => {
                this.scrollToBottom()
            });
            firebase.firestore().collection('groups').doc(this.clickOrderDetail.groupId).update({ unreadStoreMessages: this.clickOrderDetail.unread + 1 });
            this.chatImage = "";
        },
        sendDocument(){
            var input = new Object();
            input.message = this.chatDoc;
            input.title = null;
            input.fileName = '';
            input.itemfrom = '';
            input.readstatus = 'no';
            input.type = 5;
            input.sender = this.profile_email;
            input.time = firebase.firestore.FieldValue.serverTimestamp();
            input.logo = JSON.parse(localStorage.getItem("user")).user_picture;
            input.showtime = Date.parse(new Date()).toString();

            firebase.firestore().collection('groups').doc(this.clickOrderDetail.groupId).collection('messages').add(input).then(() => {
                this.scrollToBottom()
            });
            firebase.firestore().collection('groups').doc(this.clickOrderDetail.groupId).update({ unreadStoreMessages: this.clickOrderDetail.unread + 1 });
            this.chatDoc = "";
        },
        addOrderItems(){
            if(this.submitItems.length <= 0){
                this.$toast.error("Select an item to share");
                return false;   
            }
            window.$("#SelectFromorder").modal("hide");
            let selectedItem = [];
            let selectedOrderItems = [];
            for(var i=0; i<=this.itemdetails.items.length; i++){
                if(this.itemdetails.items[i]!=undefined){
                    var vm = this;
                    var item_variants = this.itemdetails.items[i].item_variants.filter(
                      function(elem) {
                        if (vm.submitItems.indexOf(elem.item_variants_id) > -1) {
                            return elem;
                        }
                      }
                    );
                    if(item_variants.length > 0){
                        this.itemdetails.items[i].item_variants = item_variants;
                        selectedItem.push(this.itemdetails.items[i]);
                    }
                }
            }

            if(selectedItem.length > 0){
                selectedItem.forEach(function (item) {
                    item.item_variants.forEach(function (variant) {
                        if (variant.is_added == 1) {
                            var item_data = {
                                item_id: item.item_id,
                                item_image: item.item_image,
                                item_name: item.item_name,
                                item_image_thumb: item.item_image_thumb,
                                item_variants_id: variant.item_variants_id,
                                brand_name: item.brand_name,
                                packaging_name: variant.packaging_name,
                                unit_name: variant.unit_name,
                                quantity: variant.quantity,
                                unit_quantity: variant.unit_quantity,
                                discounted_price: variant.discounted_price,
                                price: variant.item_price,
                                packaging: variant.packaging,
                                min_qty: variant.min_qty,
                                max_qty: variant.max_qty,
                                is_choice_addon: variant.is_choice_addon,
                                tax: variant.tax,
                                tax_discounted_price: variant.tax_discounted_price,
                            }
                            if (variant.choices_addons != null) {
                                item_data.choices_addons = variant.choices_addons;
                            } else {
                                item_data.choices_addons = null;
                            }
                            if (item.combo_variants != null) {
                                item_data.combo_variants = item.combo_variants;
                            }
                            else {
                                item_data.combo_variants = null;
                            }
                            selectedOrderItems.push(item_data);
                        }
                    });

                });
            }

            var input = new Object();
            input.message = JSON.stringify(selectedOrderItems);
            input.title = null;
            input.fileName = '';
            input.itemfrom = 'Items from order';
            input.readstatus = 'no';
            input.type = 3;
            input.sender = this.profile_email;
            input.time = firebase.firestore.FieldValue.serverTimestamp();
            input.logo = JSON.parse(localStorage.getItem("user")).user_picture;
            input.showtime = Date.parse(new Date()).toString();

            firebase.firestore().collection('groups').doc(this.clickOrderDetail.groupId).collection('messages').add(input).then(() => {
                this.scrollToBottom();  
            });
            firebase.firestore().collection('groups').doc(this.clickOrderDetail.groupId).update({ unreadStoreMessages: this.clickOrderDetail.unread + 1 });
            selectedOrderItems = [];
            //this.getChat(this.clickOrderDetail);
        },
        getOrderDetails(orderid){
            this.orderdetail.order_id = orderid;
            this.$store.dispatch("getcustomerorderdetail", this.orderdetail)
            .then(response => {
                bus.$emit("ShowOrderDetails", JSON.parse(JSON.stringify(response)));
                window.$("#order-details-popup").modal("show");
            });
        },
        chatItems(orderid){
            this.orderdetail.order_id = orderid;
            this.$store.dispatch("getcustomerorderdetail", this.orderdetail)       
            .then(response => {
                if(response.status == 1){
                    this.itemdetails = response.data;
                    window.$("#SelectFromorder").modal("show");
                }
            });
        },
        redirectToStore(storeid){
            this.$router.push({ path: `/shopdetail?storeid=${storeid}`})
        },
        openOrderItemModal(){
          this.submitItems = [];
        },
        openImageMsg(data){
            let w = window.open('about:blank');
            let image = new Image();
            image.src = data;
            setTimeout(function(){
              w.document.write(image.outerHTML);
            }, 0);
        },
        selectOrderChat(){
            var order_id = this.$route.query.orderid;
            if(order_id && this.groupDetailsArray.length > 1) {

                var groupDetailsArray = this.groupDetailsArray.slice().sort((a,b) =>{
                    var aordertime = this.normalizeMixedDataValue(a.groupOrderDetails.order_date)
                    var bordertime = this.normalizeMixedDataValue(b.groupOrderDetails.order_date)
                    return (aordertime > bordertime ? -1 : 1)
                });

                let order_chat_index = groupDetailsArray.findIndex(
                    o => o.groupOrderDetails.order_id == order_id
                );

                if(order_chat_index > -1){
                    this.previousIndex = order_chat_index;
                    this.getChat(groupDetailsArray[order_chat_index]);
                }
            }
        }
    }
}
</script>